<template>
	<Dialog
		:dialog="dialog"
		title="Expense Dialog"
		current
		v-on:close="dialog = false"
		:dialogWidth="1024"
	>
		<template v-slot:title>
			{{ updateDialog ? "Update" : "Add" }}
			Expense
			<span class="orange--text text--darken-4 barcode-font-size"> &nbsp;#{{ expense.barcode }}</span>
		</template>
		<template v-slot:body>
			<div class="min-height-280">
				<v-form ref="expenseForm" lazy-validation>
					<v-row>
						<v-col md="2" class="py-0" v-if="false">
							<label for="barcode" class="btx-label mt-1 required">Expense Claim #</label>
						</v-col>
						<v-col md="10" class="py-0" v-if="false">
							<TextInput
								hide-details
								:disabled="pageLoading || updateDialog ? true : false"
								:loading="pageLoading"
								id="barcode"
								placeholder="barcode"
								v-model="expense.barcode"
								hide-top-margin
								:rules="[vrules.required(expense.barcode, 'Expense Claim #'), barcodeError ? false : true]"
								:class="{
									required: !expense.barcode,
								}"
								v-on:change="validateBarcode(expense.barcode, 'claim-expense')"
							></TextInput>
						</v-col>

						<v-col md="2" class="py-0">
							<label for="title" class="btx-label mt-2">Expense Type</label>
						</v-col>
						<v-col md="10" class="py-0">
							<v-radio-group class="ma-0" hide-details mandatory row v-model="expense.expense_type">
								<v-radio label="Company" value="company"></v-radio>
								<v-radio label="Individual" value="individual"></v-radio>
							</v-radio-group>
						</v-col>
						<v-col md="2" class="py-0">
							<label for="title" class="btx-label mt-4 required">Title</label>
						</v-col>
						<v-col md="10" class="py-0">
							<TextInput
								hide-details
								:disabled="pageLoading"
								:loading="pageLoading"
								id="title"
								placeholder="Title"
								v-model="expense.title"
								:rules="[vrules.required(expense.title, 'Title'), barcodeError ? false : true]"
								:class="{
									required: !expense.title,
								}"
							></TextInput>
						</v-col>

						<v-col v-if="expense.expense_type === 'company'" md="2" class="py-0">
							<label for="customer" class="btx-label mt-4 required">Customer</label>
						</v-col>
						<v-col v-if="expense.expense_type === 'company'" md="10" class="py-0">
							<SelectCustomerSupplier
								id="customer"
								v-model="customer"
								:items="customerList"
								:update-detail="selectedCustomer"
								:disabled="pageLoading"
								:loading="pageLoading"
								type="customer"
								:rules="[vrules.required(customer.id, 'customer')]"
								:class="{
									required: !customer.id,
								}"
								placeholder="Customer"
								:service-address="false"
								input-id="customer-id"
								@change="getRelatedProject($event)"
							></SelectCustomerSupplier>
						</v-col>
						<v-col v-if="expense.expense_type === 'company'" md="2" class="py-0">
							<label for="project" class="btx-label mt-4 required">Project</label>
						</v-col>
						<v-col v-if="expense.expense_type === 'company'" md="10" class="py-0">
							<AutoCompleteInput
								hide-details
								:items="projectList"
								id="project"
								placeholder="Project"
								v-model="expense.project"
								:disabled="pageLoading"
								:loading="pageLoading"
								:rules="[vrules.required(expense.project, 'project')]"
								:class="{
									required: !expense.project,
								}"
								@change="getRelatedBudget($event)"
							>
							</AutoCompleteInput>
						</v-col>
						<v-col v-if="expense.expense_type === 'company'" md="2" class="py-0">
							<label for="Budget_category" class="btx-label mt-4">Budget Category</label>
						</v-col>
						<v-col v-if="expense.expense_type === 'company'" md="10" class="py-0">
							<SelectBudget
								hide-details
								return-object
								id="Budget_category"
								:items="budgetCategoryList"
								placeholder="Budget Category"
								v-model="budgetCategory"
								:disabled="pageLoading"
								:loading="pageLoading"
								@setModel="setCategory"
							>
							</SelectBudget>
						</v-col>
						<v-col md="2" class="py-0">
							<label for="amount" class="btx-label mt-4">Amount</label>
						</v-col>
						<v-col md="4" class="py-0">
							<PriceInput
								type="number"
								hide-details
								hideSpinButtons
								:disabled="pageLoading"
								:loading="pageLoading"
								id="amount"
								placeholder="Amount"
								v-model="expense.amount"
							></PriceInput>
						</v-col>

						<v-col md="2" class="py-0 text-right">
							<label for="amount" class="btx-label mt-4">Date</label>
						</v-col>
						<v-col md="4" class="py-0">
							<DatePicker
								hide-details
								:loading="pageLoading"
								placeholder="Date"
								v-model="expense.expense_date"
								:rules="[vrules.required(expense.expense_date, 'Date')]"
								:class="{
									required: !expense.expense_date,
								}"
							></DatePicker>
						</v-col>
						<v-col md="2" class="py-0">
							<label for="remark" class="btx-label mt-3">Remark</label>
						</v-col>
						<v-col md="10" class="py-0">
							<TextAreaInput
								:disabled="pageLoading"
								:loading="pageLoading"
								id="remark"
								placeholder="Remark"
								v-model="expense.remark"
							></TextAreaInput>
						</v-col>
						<v-col md="3" class="py-0">
							<label for="attachment" class="btx-label mt-4">Documents/Attachment</label>
						</v-col>
						<v-col md="9" class="py-0">
							<FileUpload v-model="expense.attachment"></FileUpload>
						</v-col>
					</v-row>
				</v-form>
			</div>
		</template>
		<template v-slot:action>
			<v-btn depressed tile :disabled="pageLoading" v-on:click="$emit('close', false)"> Close </v-btn>
			<v-btn
				class="white--text"
				depressed
				color="blue darken-4"
				tile
				:disabled="pageLoading"
				:loading="pageLoading"
				@click.prevent="updateOrCreate()"
			>
				{{ updateDialog ? "Update" : "Save" }}
			</v-btn>
		</template>
	</Dialog>
</template>

<script>
import { mapGetters } from "vuex";
import Dialog from "@/view/components/Dialog";
import SelectCustomerSupplier from "@/view/components/SelectCustomerSupplier";
import { SET_LOCAL_DB } from "@/core/services/store/config.module";
import { SET_ERROR, SET_MESSAGE, GET_PROJUCT_DROPDOWN } from "@/core/services/store/common.module";
import DatePicker from "@/view/components/DatePicker";
import TextInput from "@/view/components/TextInput";
import TextAreaInput from "@/view/components/TextAreaInput";
import AutoCompleteInput from "@/view/components/AutoCompleteInput";
import SelectBudget from "@/view/components/SelectBudget";
import PriceInput from "@/view/components/PriceInput";
import FileUpload from "@/view/components/FileUpload";
import MomentJS from "moment-timezone";
import { CreateExpense, UpdateExpense, GetExpense } from "@/core/lib/expense.lib";
import { GetAllBudget } from "@/core/lib/common.lib";
import { saveData, getData, deleteData } from "@/core/services/local.service";
import { filter } from "lodash";
export default {
	name: "expense-create",
	title: "Create Expense",
	props: {
		dialog: {
			type: Boolean,
			default: false,
		},
		uuid: {
			type: String,
			default: null,
		},
		updateDialog: {
			type: Boolean,
			default: false,
		},
		updateDetail: {
			type: Object,
			default: () => {
				return {};
			},
		},
	},
	components: {
		TextInput,
		TextAreaInput,
		AutoCompleteInput,
		SelectBudget,
		FileUpload,
		PriceInput,
		Dialog,
		DatePicker,
		SelectCustomerSupplier,
	},
	data() {
		return {
			allProjectList: [],
			customerList: [],
			allBudget: [],
			customer: {
				id: null,
				billing_address_id: null,
				contact_person_id: null,
			},
			selectedCustomer: {
				id: null,
				billing_address_id: null,
				contact_person_id: null,
			},
			budgetCategory: { text: null, cost: 0, budget_id: 0, sub_budget_id: 0 },
			expense: {
				id: null,
				uuid: null,
				barcode: null,
				attachment: [],
				customer: null,
				project: null,
				amount: null,
				title: null,
				// date: null,
				expense_type: null,
				expense_date: MomentJS().format("YYYY-MM-DD"),
				remark: null,
			},
			budgetCategoryList: [],
			pageLoading: false,
			projectList: [],
		};
	},
	computed: {
		...mapGetters(["localDB", "errors"]),
	},
	methods: {
		savetoLocal() {
			const create_expense = {
				customer: this.customer && this.customer.id,
				billing_address_id: this.customer && this.customer.billing_address_id,
				contact_person_id: this.customer && this.customer.contact_person_id,
				project: this.expense.project,
				budget_category: this.expense.budget_category,
				title: this.expense.title,
				expense_type: this.expense.expense_type,
				amount: this.expense.amount,
				remark: this.expense.remark,
				date: this.expense.expense_date,
			};
			saveData("create_expense", create_expense);
		},
		async updateOrCreate() {
			const _this = this;
			const formErrors = _this.validateForm(_this.$refs.expenseForm);
			if (formErrors.length) {
				_this.$store.commit(SET_ERROR, _this.errors.concat(formErrors));
				return false;
			}

			if (!_this.$refs.expenseForm.validate()) {
				return false;
			}
			/* budgetCategory: { text: "ds", cost: 654, budget_id: 3, sub_budget_id: 5 }, */
			// const formaData = {
			// 	uuid: _this.uuid,
			// 	barcode: _this.expense.barcode,
			// 	customer: _this.customer.id,
			// 	billing_address_id: _this.customer.billing_address_id,
			// 	contact_person_id: _this.customer.contact_person_id,
			// 	project: _this.expense.project,
			// 	budget_category:
			// 		_this.budgetCategory && _this.budgetCategory.budget_id ? _this.budgetCategory.budget_id : 0,
			// 	sub_budget:
			// 		_this.budgetCategory && _this.budgetCategory.sub_budget_id
			// 			? _this.budgetCategory.sub_budget_id
			// 			: 0,
			// 	expense_type: this.expense.expense_type,
			// 	amount: this.expense.amount,
			// 	title: _this.expense.title,
			// 	remark: _this.expense.remark,
			// };
			let formaData = {
				uuid: _this.uuid,
				barcode: _this.expense.barcode,
				expense_type: _this.expense.expense_type,
				title: _this.expense.title,
				amount: _this.expense.amount,
				remark: _this.expense.remark,
				expense_date: this.expense.expense_date,
				attachment: _this.expense.attachment,
			};
			if (this.expense.expense_type === "company") {
				const newObjet = {
					customer: _this.customer.id,
					billing_address_id: _this.customer.billing_address_id,
					contact_person_id: _this.customer.contact_person_id,
					budget_category:
						_this.budgetCategory && _this.budgetCategory.budget_id ? _this.budgetCategory.budget_id : 0,
					sub_budget:
						_this.budgetCategory && _this.budgetCategory.sub_budget_id
							? _this.budgetCategory.sub_budget_id
							: 0,
					project: _this.expense.project,
					expense_type: this.expense.expense_type,
					amount: this.expense.amount,
					title: _this.expense.title,
					remark: _this.expense.remark,
				};
				formaData = {
					...formaData,
					...newObjet,
				};
			}
			try {
				_this.pageLoading = true;
				if (_this.updateDialog) {
					const { uuid } = await UpdateExpense(this.uuid, formaData);
					if (uuid) {
						_this.$store.commit(SET_MESSAGE, [
							{ model: true, message: "Success ! Expense Claim has been updated." },
						]);
					}
				} else {
					const { uuid } = await CreateExpense(formaData);
					if (uuid) {
						_this.$store.commit(SET_MESSAGE, [
							{ model: true, message: "Success ! Expense Claim has been created." },
						]);
					}
				}
				deleteData("create_expense");
				_this.$emit("success", false);
				_this.$emit("close", false);
			} catch (e) {
				console.log(e);
			} finally {
				_this.pageLoading = false;
			}
		},
		async getExpense() {
			const _this = this;
			_this.pageLoading = true;
			try {
				if (_this.updateDialog) {
					const data = await GetExpense(this.uuid);
					// console.log(data);
					_this.uuid = data.uuid;
					_this.expense = {
						id: data.id ? data.id : null,
						expense_type: data.expense_type ? data.expense_type : null,
						amount: data.amount ? data.amount : null,
						uuid: data.uuid ? data.uuid : null,
						barcode: data.barcode ? data.barcode : null,
						project: data.project && data.project.id ? data.project.id : null,
						/* budget_category: data.budget_category ? data.budget_category : null, */
						title: data.title ? data.title : null,
						remark: data.remark ? data.remark : null,
					};
					this.expense.expense_date = data.expense_date;
					this.selectedCustomer = {
						id: data.customer && data.customer.id ? data.customer.id : null,
						billing_address_id: data.billing_address_id ? data.billing_address_id : null,
						contact_person_id: data.contact_person_id ? data.contact_person_id : null,
					};
					this.getRelatedBudget(data.project?.id);
					/* budgetCategory: { text: "ds", cost: 654, budget_id: 3, sub_budget_id: 5 }, */
					this.budgetCategory = {
						value: data.sub_budget ? `sub-budget-${data.sub_budget}` : `budget-${data.budget_category}`,
						budget_id: data.budget_category,
						sub_budget_id: data.sub_budget,
					};
					const payload = {
						module: "claim-expense",
						id: data.id,
					};
					this.getAllProject(payload);
				} else {
					_this.expense = {
						..._this.expense,
						id: null,
						uuid: null,
						project: null,
						title: null,
						remark: null,
					};
					this.budgetCategory = null;
					_this.selectedCustomer = {
						id: null,
						billing_address_id: null,
						contact_person_id: null,
					};
				}
			} catch (error) {
				console.log(error);
			} finally {
				_this.pageLoading = false;
			}
		},
		getAllProject(payload) {
			this.$store.dispatch(GET_PROJUCT_DROPDOWN, payload).then(({ projects }) => {
				this.allProjectList = projects;
			});
		},
		getRelatedProject($event) {
			const customer = $event && $event.id;
			this.projectList = filter(this.allProjectList, (row) => {
				return row.customer == customer;
			});
		},
		async getAllBudget() {
			const data = await GetAllBudget();
			this.allBudget = data;
			this.getExpense();
		},
		getRelatedBudget($event) {
			const project = $event;
			const budget_list = filter(this.allBudget, (row) => {
				return row.project == project;
			});
			// console.log(budget_list);

			let buget_data = [];
			budget_list.forEach((element, index) => {
				// console.log(element);
				buget_data.push({
					text: element.name,
					disabled: element.sub_budgets.length ? true : false,
					numbering: index + 1,
					value: `budget-${element.id}`,
					cost: element.cost,
					budget_id: element.id,
					sub_budget_id: null,
				});
				element["sub_budgets"].forEach((row, index) => {
					buget_data.push({
						text: row.name,
						subNumbering: index + 1,
						value: `sub-budget-${row.id}`,
						cost: row.cost,
						budget_id: row.budget,
						sub_budget_id: row.id,
					});
				});
			});
			console.log(buget_data);
			this.budgetCategoryList = buget_data;
		},
		init() {
			const _this = this;
			let localData = getData("create_expense");
			if (localData) {
				_this.selectedCustomer = {
					id: localData.customer,
					billing_address_id: localData.billing_address_id,
					contact_person_id: localData.contact_person_id,
				};
				_this.expense.project = localData.project;
				_this.expense.budget_category = localData.budget_category;
				_this.expense.title = localData.title;
				_this.expense.description = localData.description;
				_this.expense.remark = localData.remark;
			}
		},
		setCategory(data) {
			const categoryExist = JSON.stringify(this.budgetCategory) === JSON.stringify(data);
			if (categoryExist) {
				this.budgetCategory = null;
			} else {
				this.budgetCategory = data;
			}
		},
	},
	mounted() {
		this.init();
		this.getAllBudget();
		this.$store.dispatch(SET_LOCAL_DB).then(() => {
			this.customerList = this.localDB("customers", []);
		});
		const payload = {
			module: "claim-expense",
			id: null,
		};
		this.getAllProject(payload);
		if (!this.updateDialog) {
			this.genrateBarcode("claim-expense").then((output) => {
				this.expense.barcode = output;
			});
		}
	},
};
</script>
