<template>
	<Dialog :dialog.sync="filterDialog" :dialog-width.sync="dialogWidth">
		<template v-slot:title>Filter {{ title }}</template>
		<template v-slot:body>
			<v-row>
				<template v-for="(filter, index) in btxFilter">
					<!-- <v-col > -->
					<v-col
						:key="index"
						class="my-auto py-0"
						md="6"
						v-if="filter.filter_type == 'select' && filter.model === 'type'"
					>
						<label :for="filter.model" class="btx-label mt-2">{{ filter.label }}</label>
						<AutoCompleteInput
							hide-details
							:items.sync="expenseTypeList"
							item-text="text"
							item-value="value"
							:placeholder="filter.label"
							v-model="btxQuery[filter.text]"
							@change="changeExpenseType(btxQuery[filter.text])"
						></AutoCompleteInput>
					</v-col>
					<v-col
						:key="index"
						class="my-auto py-0"
						md="6"
						v-if="expenseType == 'company' && filter.text == 'project'"
					>
						<label :for="filter.model" class="btx-label mt-2">{{ filter.label }}</label>
						<AutoCompleteInput
							hide-details
							:items.sync="allProjectList"
							item-text="text"
							item-value="value"
							:placeholder="filter.label"
							v-model="btxQuery[filter.text]"
							@change="getRelatedBudget(btxQuery[filter.text])"
						></AutoCompleteInput>
					</v-col>
					<v-col
						:key="index"
						class="my-auto py-0"
						md="6"
						v-if="expenseType == 'company' && filter.text == 'budget_category'"
					>
						<label :for="filter.model" class="btx-label mt-2">{{ filter.label }}</label>
						<AutoCompleteInput
							hide-details
							:items.sync="budgetCategoryList"
							item-text="name"
							item-value="id"
							:placeholder="filter.label"
							@change="getRelatedSubBudget(btxQuery[filter.text])"
							v-model="btxQuery[filter.text]"
						></AutoCompleteInput>
					</v-col>
					<v-col
						:key="index"
						class="my-auto py-0"
						md="6"
						v-if="expenseType == 'company' && filter.text == 'budget_subcategory'"
					>
						<label :for="filter.model" class="btx-label mt-2">{{ filter.label }}</label>
						<AutoCompleteInput
							hide-details
							:items.sync="subBudgetCategoryList"
							item-text="name"
							item-value="id"
							:placeholder="filter.label"
							v-model="btxQuery[filter.text]"
						></AutoCompleteInput>
					</v-col>
					<v-col :key="index" class="my-auto py-0" md="6" v-if="filter.filter_type == 'date-range'">
						<label :for="filter.model" class="btx-label mt-2">{{ filter.label }}</label>
						<DateRangePickerInput
							range
							:id="filter.model"
							:placeholder="filter.label"
							v-model="btxQuery[filter.text]"
						></DateRangePickerInput>
					</v-col>
				</template>
			</v-row>
		</template>
		<template v-slot:action>
			<v-btn
				class="white--text"
				depressed
				color="blue darken-4"
				v-on:click="$emit('do-search', btxQuery)"
				tile
			>
				Filter
			</v-btn>
			<v-btn depressed tile v-on:click="$emit('close', true)"> Close </v-btn>
		</template></Dialog
	>
</template>

<script>
import Dialog from "@/view/components/Dialog";
// import TextInput from "@/view/components/TextInput";
import DateRangePickerInput from "@/view/components/DateRangePicker";
import { toSafeInteger } from "lodash";
import { mapGetters } from "vuex";
import AutoCompleteInput from "@/view/components/AutoCompleteInput";
// import { GetAllProduct } from "@/core/lib/common.lib";
import { GET_PROJUCT_DROPDOWN } from "@/core/services/store/common.module";
import { filter } from "lodash";
import { GetAllBudget } from "@/core/lib/common.lib";

export default {
	name: "filter-template",
	data() {
		return {
			expenseTypeList: [
				{
					text: "Company",
					value: "company",
				},
				{
					text: "Individual",
					value: "individual",
				},
			],
			allProductList: [],
			supplierList: [],
			allProjectList: [],
			allBudget: [],
			budgetCategoryList: [],
			subBudgetList: [],
			expenseType: null,
			subBudgetCategoryList: [],
		};
	},
	props: {
		title: {
			type: String,
			default: null,
		},
		filterDialog: {
			type: Boolean,
			default: false,
		},
		btxFilter: {
			type: Array,
			default() {
				return [];
			},
		},
		btxQuery: {
			type: Object,
			default() {
				return {};
			},
		},
	},
	components: {
		Dialog,
		DateRangePickerInput,
		AutoCompleteInput,
	},
	computed: {
		dialogWidth() {
			return toSafeInteger((document.body.clientWidth / 100) * 60);
		},
		...mapGetters(["errors", "localDB"]),
	},
	methods: {
		changeExpenseType(expenseType) {
			this.expenseType = expenseType;
			this.btxQuery["project"] = null;
			this.btxQuery["budget_subcategory"] = null;
			this.btxQuery["budget_category"] = null;
		},
		getAllProject(payload) {
			this.$store.dispatch(GET_PROJUCT_DROPDOWN, payload).then(({ projects }) => {
				this.allProjectList = projects;
			});
		},
		getRelatedBudget(id) {
			const budget_list = filter(this.allBudget, (row) => {
				return row.project == id;
			});
			this.budgetCategoryList = budget_list;
		},
		getRelatedSubBudget(id) {
			const budget_list = filter(this.budgetCategoryList, (row) => {
				return row.id == id;
			});
			this.subBudgetCategoryList = budget_list[0]?.sub_budgets;
		},
		async getAllBudget() {
			const data = await GetAllBudget();
			this.allBudget = data;
		},
	},
	mounted() {
		this.supplierList = this.localDB("suppliers", []);
		const payload = {
			module: "claim-expense",
			id: null,
		};
		this.getAllBudget();
		this.getAllProject(payload);
		this.btxQuery["project"] = "company";
	},
};
</script>
